import { Container, Typography, Box, Link, useTheme, useMediaQuery } from "@mui/material";
import { graphql } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";
import { json } from "fetchkit";
import Layout from "../../layout/Layout";
import ReadRecord from "../../components/ReadRecord";
import WhiteBook2022 from "../../images/2022.gif";
import WhiteBook2021 from "../../images/2021.gif";

export default function IndexPage({ data, year }: any) {
  const [record, setRecord] = React.useState([]);
  const [select, setSelect] = React.useState(0);

  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  React.useEffect(() => {
    // read route params
    const params = window.location.hash
    if (!params) return
    setSelect(parseInt(params.slice(-1)))

  }, [])
  React.useEffect(() => {
    const getRecord = async () => {
      try {
        const getResponse = await fetch(`/api/get-visit-records`, json({}));
        const getData = await getResponse.json();
        if (getData.message) {
          throw Error(getData.message);
        }
        setRecord(getData);
      } catch (e) {
        alert((e as Error).message);
      }
    };
    getRecord();
  }, []);
  return (
    <Layout title={year + "中国NFT产业白皮书"}>
      <Helmet>
        <title>{year + "中国NFT产业白皮书"}</title>
      </Helmet>
      <Box
        sx={{
          bgcolor: "background.paper",
          // pt: 8,
          pb: 6,
        }}
      >
        <Container
          maxWidth="xl"
          sx={{
            display: "flex",
            alignItems: { xs: "center", md: "start" },
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Box sx={{
            display: { xs: "flex", md: "none" },
            justifyContent: "space-between",
            alignItems: "center",
            px: { xs: 2, md: 10 },
            width: "100vw",
            height: "50px",
          }}>
            {[{ title: '简介' }, { title: '目录' }, { title: '链上览痕' }].map((val, index) => {

              return <Typography onClick={() => {
                setSelect(index)
              }} key={index} sx={{
                color: index == select ? '#1383E2' : '#000',
                textDecorationThickness: "2px",
                textDecorationLine: index == select ? 'underline' : 'none',
                textUnderlineOffset: '8px',
                fontWeight: index == select ? 600 : 400,
                cursor: 'pointer',
              }}>{val.title}</Typography>
            })}
          </Box>
          {
            mdDown ? <>
              {select == 0 && <Box width={{ xs: "80vw", md: "600px" }} mr={{ sm: 0, md: 10 }} sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}>
                <Box
                  width={{ xs: "60vw", md: "600px" }}
                  sx={{
                    mb: 1,
                  }}
                  component="img"
                  src={year == 2021 ? WhiteBook2021 : WhiteBook2022}
                />
                <Box sx={{
                  textAlign: 'center',
                  bgcolor: '#d5e4f7',
                  color: '#6c6c6c',
                  py: 1,
                  px: 3,
                  mb: 1,
                }}>白皮书简介</Box>
                <Typography
                  fontSize={{ xs: "16px", md: "18px" }}
                  align="left"
                  padding="16px"
                  paragraph
                  sx={{
                    border: '4px solid #5793df',
                    boxShadow: '0 0 5px #aaa',
                  }}
                >
                  {year == 2021 ? '是一份关于中国数字藏品产业现状及未来发展趋势的权威性报告。该白皮书由行业权威机构和专业学者共同撰写，以全面深入的调研和分析为基础，从行业规模、市场需求、技术应用、政策环境等多个方面全面展示了中国数字藏品产业的现状和发展趋势。该白皮书旨在为数字藏品行业的从业者、投资者、政策制定者等各方提供参考和指导，促进数字藏品行业的健康有序发展。' :
                    '北京邮电大学网络经济与信息化研究中心、北京大学市场经济研究中心、中国信息通信研究院工业互联网与物联网研究所三方联合共同编写了《2022中国NFT产业白皮书——数字藏品赋能品牌营销》。作为本系列的第二本白皮书，不仅承袭了第一本白皮书的数字藏品主题，更进一步聚焦对数字藏品在品牌营销领域的应用、发展及展望进行剖析。研究团队通过收集一手市场数据，对数字藏品营销模式、不同营销模式的市场规模、产业布局、行业应用等进行了多维度的深入分析；并通过发放用户问卷清晰描绘了品牌类数字藏品的用户画像；还与业内多位专家访谈，利用扎根理论分析归纳数字藏品营销效果的影响因素，最终提出展望与建议。我们希冀通过本白皮书，打开数字藏品的应用新场景，为行业未来发展以及价值探索带来更多可能性。'}
                </Typography>
              </Box>
              }

              {select == 1 && <Box width={{ xs: "90vw", md: "100%" }}>
                <Typography
                  align="center"
                  fontSize={{ xs: "24px", md: "38px", fontWeight: 800 }}
                // color="#1383E2"
                >
                  {year}年中国NFT产业白皮书
                </Typography>
                <Typography
                  align="right"
                  fontSize={{ xs: "24px", md: "38px" }}
                  mb={2}
                  fontWeight={600}
                // color="#1383E2"
                >
                  ——数字藏品赋能品牌营销
                </Typography>
                <Box sx={{ ml: { xs: "0px", md: "20px" } }}>
                  {data.allMdx.nodes
                    .filter((val: any) => {
                      return val.frontmatter.year == year;
                    })
                    .map(({ id, frontmatter }: any) => (
                      <Box sx={{
                        display: "flex",
                        fontWeight: 600,
                        mb: 5
                      }}>
                        <Box sx={{
                          width: "20px",
                          height: "20px",
                          bgcolor: "#1383E2",
                          mr: 1,
                        }}></Box>
                        <Typography
                          fontSize={{ xs: "18px", md: "22px" }}
                          gutterBottom
                          noWrap
                          color="text.primary"
                        >
                          <Link
                            underline="none"
                            href={`/${frontmatter!.order}/`}
                            color="#1383E2"
                          // color="text.primary"
                          >
                            {frontmatter!.title}
                          </Link>
                        </Typography>
                      </Box>

                    ))}
                </Box>

              </Box>}

              {select == 2 && <ReadRecord records={record}></ReadRecord>}
            </> : <>
              <Box width={{ xs: "80vw", md: "600px" }} mr={{ sm: 0, md: 10 }} mt="30px">
                <Box
                  width={{ xs: "80vw", md: "600px" }}
                  component="img"
                  src={year == 2021 ? WhiteBook2021 : WhiteBook2022}
                />

                <Typography
                  fontSize={{ xs: "14px", md: "18px" }}
                  align="center"
                  color="text.secondary"
                  paragraph
                >
                  {year == 2021 ? '是一份关于中国数字藏品产业现状及未来发展趋势的权威性报告。该白皮书由行业权威机构和专业学者共同撰写，以全面深入的调研和分析为基础，从行业规模、市场需求、技术应用、政策环境等多个方面全面展示了中国数字藏品产业的现状和发展趋势。该白皮书旨在为数字藏品行业的从业者、投资者、政策制定者等各方提供参考和指导，促进数字藏品行业的健康有序发展。' :
                    '北京邮电大学网络经济与信息化研究中心、北京大学市场经济研究中心、中国信息通信研究院工业互联网与物联网研究所三方联合共同编写了《2022中国NFT产业白皮书——数字藏品赋能品牌营销》。作为本系列的第二本白皮书，不仅承袭了第一本白皮书的数字藏品主题，更进一步聚焦对数字藏品在品牌营销领域的应用、发展及展望进行剖析。研究团队通过收集一手市场数据，对数字藏品营销模式、不同营销模式的市场规模、产业布局、行业应用等进行了多维度的深入分析；并通过发放用户问卷清晰描绘了品牌类数字藏品的用户画像；还与业内多位专家访谈，利用扎根理论分析归纳数字藏品营销效果的影响因素，最终提出展望与建议。我们希冀通过本白皮书，打开数字藏品的应用新场景，为行业未来发展以及价值探索带来更多可能性。'}
                </Typography>
              </Box>
              <Box width={{ xs: "90vw", md: "100%" }} mt="30px" >
                <Typography
                  fontSize={{ xs: "24px", md: "38px", fontWeight: 800 }}
                // color="#1383E2"
                >
                  目录
                </Typography>
                <Box sx={{ ml: { xs: "8px", md: "20px" } }}>
                  {data.allMdx.nodes
                    .filter((val: any) => {
                      return val.frontmatter.year == year;
                    })
                    .map(({ id, frontmatter }: any) => (
                      <Typography
                        fontSize={{ xs: "18px", md: "22px" }}
                        gutterBottom
                        noWrap
                        color="text.primary"
                      >
                        <Link
                          underline="none"
                          href={`/${frontmatter!.order}/`}
                          color="#1383E2"
                        // color="text.primary"
                        >
                          {frontmatter!.title}
                        </Link>
                      </Typography>
                    ))}
                </Box>
                <ReadRecord records={record}></ReadRecord>
              </Box></>
          }
        </Container>
      </Box>
    </Layout>
  );
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: frontmatter___order, order: ASC }) {
      nodes {
        id
        frontmatter {
          order
          title
          year
        }
      }
    }
  }
`;
